import { createStore } from 'vuex'
import import_data from '../assets/js/app-conf'
import router from '../router';
import axios from 'axios';

export default createStore({
  state: {
    data: import_data,
    current_step: 0,
    current_question: 0,
    carbon_footprint: 0,
    csv: [],
    is_end : false,
    modal: false,
    count: 0,
    user: {
      age: false,
      local: false,
      education: false
    }
  },
  mutations: {
    get_csv_count(state){
      axios.get('https://fustmenteskalkulator.hu/get_csv.php')
          .then(function (response) {
            // handle success
            console.log(response);
            console.log(response.data);

            state.count = response.data;
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
    },
    next_question(state) {

      let selected = false;
      for(let i = 0; i < state.data.steps[state.current_step].questions[state.current_question].ans.length; i++){
        let a = state.data.steps[state.current_step].questions[state.current_question].ans[i];
        if(a.selected){
          selected = true;
        }
      }

      if(!selected) return;

      let current_question_max = state.data.steps[state.current_step].questions.length -1; //max number of questions in step

      if(current_question_max > state.current_question){
        state.current_question++;

      } else {
        state.current_question = 0;
        state.current_step++;
      }

      current_question_max = state.data.steps[state.current_step].questions.length -1; //max number of questions in step
      let max_steps = state.data.steps.length-1; //max number of steps
      if(max_steps === state.current_step && current_question_max === state.current_question){
        console.log('its the end');
        state.is_end = true;
      }
    },
    prev_question(state){
      let current_question_max = state.data.steps[state.current_step].questions.length -1; //max number of questions in step
      if(0 === state.current_step && 0 === state.current_question){
        return;
      }
      if(state.current_question > 0){
        state.current_question = state.current_question - 1;
        state.is_end = false;
      } else {
        state.current_step = state.current_step - 1;
        current_question_max = state.data.steps[state.current_step].questions.length -1;
        state.current_question = current_question_max;
      }
    },
    next_step(state) {
      state.current_step++;
    },
    prev_step(state){
      state.current_step--;
    },
    set_step(state, num) {
      state.current_step = num;
    },
    set_question(state, num) {
      state.current_question = num;
    },
    update_carbon_footprint(state, num){
      state.carbon_footprint += num;
    },
    select(state, index){

      for(let i = 0; i < state.data.steps[state.current_step].questions[state.current_question].ans.length; i++){
        let a = state.data.steps[state.current_step].questions[state.current_question].ans[i];
        a.selected = false;
      }
      state.data.steps[state.current_step].questions[state.current_question].ans[index].selected = true;
    },
    toggle_modal(state){
      state.modal = !state.modal;
    },
    set_user(state,  user) {

      state.user = user;

      for (let i = 0; i < state.data.steps.length; i++) {
        let step =  state.data.steps[i];
        for (let j = 0; j < step.questions.length; j++) {
          let question = step.questions[j];
          for (let k = 0; k < question.ans.length; k++) {
            let answer = question.ans[k];
            if(answer.selected){
              state.carbon_footprint = state.carbon_footprint + answer.val;
            }
          }
        }
      }


      router.push('/eredmeny');

    },
    reset(state){
      state.current_step = 0;
      state.current_question = 0;
      state.carbon_footprint = 0;
      state.is_end = false;
      state.modal = false;
      state.count = 0;

      for (let i = 0; i < state.data.steps.length; i++) {
        let step =  state.data.steps[i];
        for (let j = 0; j < step.questions.length; j++) {
          let question = step.questions[j];
          for (let k = 0; k < question.ans.length; k++) {
            let answer = question.ans[k];
            answer.selected = false;
          }
        }
      }

      router.push('/');
    },
    test() {
      const csv = "\"Az Ön háztartásában mekkora az éves átlagos villamosenergia-fogyasztás?\",\"Az Ön háztartásában mekkora az éves átlagos gázfogyasztás ?\",\"Autóval közlekedik?\",\"Közösségi közlekedéssel jár?\",\"Átlagosan hány órát repül évente?\",\"Milyen gyakran fogyaszt bioélelmiszert?\",\"Milyen gyakran fogyaszt húst/tejterméket?\",\"Milyen gyakran fogyaszt helyben termesztett/előállított élelmiszert?\",\"Milyen gyakran fogyaszt előre csomagolt élelmiszert? \",\"Komposztálja az élelmiszer-hulladékot?\",\"Mekkora a …egmagasabb iskolai végzettsége?\"\n\"Az átlagosnál kisebb otthon (1500 kWh/év fogyasztás)\",\"Átlagos otthon (10830 kWh/év fogyasztás)\",\"Átlag fölötti éves futás (25000 km/év) \",\"Nem\",\"1-3 óra\",\"Soha\",\"Minden nap\",\"Alkalmanként\",\"Rendszeresen \",\"Néha\",\"Az átlagosnál magasabb (minden nap vagy a hét legtöbb napján) \",\"Nem\",\"Igen\",\"Füstmentes alternatívát (elektronikus cigaretta, füstmentes dohánytermék, nikotinpárna stb.) használok\",\"25-35\",\"főváros\",\"kevesebb, mint 8 osztály\"";
      try {

        axios.post('save.php',  csv).then(function(response){
          console.log('SUCCESS!!');
          console.log(response);
        })
            .catch(function(){
              console.log('FAILURE!!');
            });

      } catch (e) {
        console.log(e);
      }

    }
  },
  actions: {
    check_csv(context){
      context.commit('get_csv_count');
    },
    next_question(context) {
      context.commit('next_question');
    },
    prev_question(context) {
      context.commit('prev_question')
    },
    next_step(context) {
      context.commit('next_step')
    },
    prev_step(context) {
      context.commit('prev_step')
    },
    update_carbon_footprint(context, num){
      context.commit('update_carbon_footprint', num)
    },
    select(context, index) {
      context.commit('select', index);
    },
    toggle_modal(context) {
      context.commit('toggle_modal');
    },
    test(context) {
      context.commit('test');
    },
    reset(context) {
      context.commit('reset');
    },

  },
  getters: {
    question_max(state){
      return state.data.steps[state.current_step].questions.length - 1;
    },
    step_max(state){
      return state.data.steps[state.current_step].questions.length-1;
    }
  },
  modules: {

  }
})
