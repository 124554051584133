<template>
  <div @click="idleSet">
    <router-view />
  </div>
</template>
<script>
  //import AgeGate from "./components/ageGate";
  export default {
    //components: {AgeGate},
    data(){
      return {
        idle: 0
      }
    },
    mounted() {
      //this.cookieCheck();
      console.log('mounted');
      setInterval(this.checkIdle, 1000);
    },
    methods: {
      idleSet(){
        this.idle = 0;
      },
      checkIdle(){
        if(this.idle >= 90) {
          console.log('##REDIRECT');
          this.$router.push('/');
        } else {
          this.idle++;
        }

      }
    }
  }
</script>
<!-- OneTrust Cookies Consent Notice end for dobjle100at.hu -->
