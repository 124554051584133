<template>
<div>
  <div id="age_gate" v-if="open">
    <div class="panel">
      <span class="circle">18</span>
      <h3>Elmúltál 18 éves?</h3>
      <p>
        Ez a tartalom kizárólag nagykorú személyek számára nyújt tájékoztatást! Az "Igen" gombra kattintással kijelented, hogy elmúltál 18 éves.
      </p>

      <router-link to="/teszt" class="btn">
        Igen, belépek
      </router-link>

      <!--span class="btn">Nem</span-->
    </div>
  </div>
  <section id="wellcome" class="screen" style="background-image: url('//fustmenteskalkulator.hu/img/backgrounds/bg.jpg');">
    <div class="container" >
      <div class="inner">

        <img src="@/assets/fustmentes_logo.png" alt="" class="logo">

        <h1>
          <span>
            Kíváncsi vagy
          </span>
          <small>
            Mekkora a CO<sub>2</sub> kibocsátásod?
          </small>
        </h1>


        <!-- class="button start-btn">
          Kattints és tudd meg!
          <img src="@/assets/hand.png" alt="" class="hand">
        </router-link-->

        <span class="button start-btn" @click="open=true">
          Kattints és tudd meg!
          <img src="@/assets/hand.png" alt="" class="hand">
        </span>


      </div>
    </div>

  </section>

</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data(){
    return {
      open: false
    }
  },
  mounted() {

  },
  methods: {
    test(){
        this.$store.dispatch('test');
    }
  }
}
</script>
