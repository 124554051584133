import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/sass/style.css"
import VueCookies from 'vue3-cookies'

createApp(App).use(store).use(router).use(VueCookies, {
    expireTimes: "30d",
    secure: true
}).mount('#app');

